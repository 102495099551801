/* eslint-disable */
import {Link, Navigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {GridRenderCellParams, GridSortModel, useGridApiRef} from '@mui/x-data-grid-pro'
import {Button, Input, Spin} from 'antd'
import classes from './UserList.module.css'
import RadioGroup from '../common/RadioGroup/RadioGroup'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {
    DeleteUserThunk,
    EditUserThunk,
    GetUserListThunk,
    selectUserList,
    selectUserListRequestParams,
    getUserGeneralData,
    setSelectedUserData,
    setUserDetailsActiveTabKey,
    selectUserListManualParams,
    setUserListManualParams,
    ConfirmUserRegistration
} from '../../store/userReducer'
import {UserType, UserStatusType} from '../../types/userTypes'
import MuiTable from '../common/MuiTable/MuiTable'
import ConfirmationModal from '../common/ConfirmationModal/ConfirmationModal'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import {ReactComponent as EditIcon} from './../../img/icons/edit.svg'
import {ReactComponent as DeleteIcon} from './../../img/icons/delete.svg'
import {ReactComponent as ActivateIcon} from './../../img/icons/activate.svg'
import {ReactComponent as DeactivateIcon} from './../../img/icons/deactivate.svg'
import {selectIsAdmin, selectUserData} from '../../store/currentUserReducer'
import TooltipHint from '../common/TooltipHint/TooltipHint'
import {getDisplayData} from '../../helpers/filtersHelper'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const UserList = () => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    const {t} = useTranslation(['pages', 'app'])
    const dispatch = useAppDispatch()
    const apiRef = useGridApiRef()
    const userList = useAppSelector(selectUserList)
    const userListRequestParams = useAppSelector(selectUserListRequestParams)
    const userListManualParams = useAppSelector(selectUserListManualParams)
    const isAdmin = useAppSelector(selectIsAdmin)
    const currentUser = useAppSelector(selectUserData)


    const userStatusOptions: { label: string, value: UserStatusType }[] = [
        {label: t('userList.toolbar.listFilterOptions.active'), value: 'ACTIVE'},
        {label: t('userList.toolbar.listFilterOptions.inactive'), value: 'INACTIVE'},
        {label: t('userList.toolbar.listFilterOptions.pending'), value: 'PENDING'},
        // {label: t('userList.toolbar.listFilterOptions.all'), value: 'ALL'},
    ]

    const [confirmationModalData, setConfirmationModalData] = useState<null | { type: 'delete' | 'deactivate' | 'activate', user: UserType }>(null)
    const [dataForDisplay, setDataForDisplay] = useState<{ data: UserType[] | null, totalCount: number }>({
        data: null,
        totalCount: 0
    })
    const [searchValue, setSearchValue] = useState('')

    const onUpdateUserRegistration = (user_id: number) => {
        dispatch(ConfirmUserRegistration({user_id: user_id, is_confirmed: true}))
            .then(() => {
                dispatch(GetUserListThunk(userListRequestParams))
            })
    }

    const onCancelUserRegistration = (user_id: number) => {
        dispatch(ConfirmUserRegistration({user_id: user_id, is_confirmed: false}))
            .then(() => {
                dispatch(GetUserListThunk(userListRequestParams))
            })
    }

    const columns = [
        {
            field: 'first_name',
            headerName: t('userList.table.columnTitles.name'),
            flex: 1,
            minWidth: 120,
            filterable: true,
            renderCell: (params: GridRenderCellParams<UserType, Date>) => (
                !params.row.first_name && !params.row.last_name ? (
                    <div className={classes.nickname}>
                        {params.row?.user_name || ''}
                    </div>
                ) : (
                    <div className={classes.userNameColumn}>
                        {params.row.first_name + ' ' + params.row.last_name}
                    </div>
                )
            )
        },
        ...(userListManualParams?.activeStatus !== 'PENDING'
                ? [ {
                    field: 'rolesString',
                    headerName: t('userList.table.columnTitles.roles'),
                    flex: 1,
                    minWidth: 100,
                    sortable: true,
                    filterable: true,
                    cellClassName: 'tableSecondaryTextStyle',
                }]
                : []
        ),
        ...(userListManualParams?.activeStatus === 'ALL'
                ? [{
                    field: 'is_active',
                    headerName: t('userList.table.columnTitles.status'),
                    flex: 0.5,
                    minWidth: 100,
                    filterable: false,
                    cellClassName: 'tableSecondaryTextStyle',
                    renderCell: (params: GridRenderCellParams<any, Date>) => (
                        <div className={`${classes.status} ${!!params.row.is_active ? classes.active : ''}`}>
                            {params.row.is_active === true
                                ? t('userList.toolbar.listFilterOptions.active')
                                : t('userList.toolbar.listFilterOptions.inactive')
                            }
                        </div>
                    )
                }]
                : []
        ),
        {
            field: 'email',
            headerName: t('userList.table.columnTitles.email'),
            flex: 1.2,
            minWidth: 100,
            filterable: true,
            cellClassName: 'tableSecondaryTextStyle'
        },
        ...(userListManualParams?.activeStatus !== 'PENDING'
            ?
            [  {
                field: 'phone_number',
                headerName: t('userList.table.columnTitles.phone'),
                flex: 1.2,
                minWidth: 100,
                filterable: true,
                cellClassName: 'tableSecondaryTextStyle'
            },
                {
                    field: 'creditsBalanceString',
                    headerName: t('userList.table.columnTitles.credits'),
                    flex: 1.2,
                    minWidth: 100,
                    sortable: true,
                    filterable: true,
                    cellClassName: 'tableSecondaryTextStyle',
                    renderCell: (data: any) => {
                        return (
                            data.row.billing_info !== null && data.row.creditsBalanceString
                                ? <div className={classes.columnBadge}>
                                    {data.row.creditsBalanceString}
                                </div>
                                : <></>
                        )
                    }
                },
                {
                    field: 'availablePersonString',
                    headerName: t('userList.table.columnTitles.patientAvailable'),
                    flex: 1.2,
                    minWidth: 100,
                    filterable: true,
                    sortable: true,
                    cellClassName: 'tableSecondaryTextStyle',
                    renderCell: (data: any) => {
                        return (
                            <div>{data.row.billing_info !== null ? data.row.availablePersonString : ''}</div>
                        )
                    }
                },
                {
                    field: 'comment',
                    headerName: t('userList.table.columnTitles.comment'),
                    flex: 1.2,
                    minWidth: 100,
                    filterable: true,
                    cellClassName: 'tableSecondaryTextStyle'
                },
                {
                    field: 'last_activity',
                    headerName: t('userList.table.columnTitles.lastActivity'),
                    flex: 1.2,
                    minWidth: 100,
                    filterable: false,
                    sortable: true,
                    cellClassName: 'tableSecondaryTextStyle',
                    renderCell: (data: any) => {
                        const {timeZone} = Intl.DateTimeFormat().resolvedOptions()
                        return (
                            <div>
                                {data.row.last_activity
                                    ? dayjs.tz(dayjs.utc(data.row.last_activity), timeZone).format('YYYY-MM-DD HH:mm')
                                    : ''
                                }
                            </div>
                        )
                    }
                } ]
                :
            [ {
                field: 'license_approved_date',
                headerName: t('userList.table.columnTitles.license_approved_date'),
                flex: 1.2,
                minWidth: 100,
                filterable: false,
                sortable: true,
                cellClassName: 'tableSecondaryTextStyle',
                renderCell: (data: any) => {
                    const {timeZone} = Intl.DateTimeFormat().resolvedOptions()
                    return (
                        <div>
                            {data.row.license_approved_date
                                ? dayjs.tz(dayjs.utc(data.row.license_approved_date), timeZone).format('YYYY-MM-DD HH:mm')
                                : ''
                            }
                        </div>
                    )
                }
            }]
        ),
        ...(userListManualParams?.activeStatus !== 'PENDING'
            ?
            [{
                field: 'Actions',
                headerName: '',
                sortable: false,
                filterable: false,
                flex: 0.1,
                minWidth: 120,
                renderCell: (params: GridRenderCellParams<UserType, Date>) => (
                    params.row.user_id !== currentUser.user_id
                        ? <div className={classes.tableActions}>
                            <TooltipHint
                                title={params.row.is_active ? t('userList.table.actionHints.deactivate') : t('userList.table.actionHints.activate')}>
                                {params.row.is_active
                                    ? (
                                        <DeactivateIcon
                                            style={{marginBottom: '3px'}}
                                            onClick={() => setConfirmationModalData({type: 'deactivate', user: params.row})}
                                        />
                                    ) : (
                                        params.row.is_btn_activate
                                        &&
                                        <ActivateIcon
                                            style={{marginBottom: '3px'}}
                                            onClick={() => setConfirmationModalData({type: 'activate', user: params.row})}
                                        />
                                    )
                                }
                            </TooltipHint>
                            <TooltipHint title={t('userList.table.actionHints.delete')}>
                                <DeleteIcon onClick={() => setConfirmationModalData({type: 'delete', user: params.row})}/>
                            </TooltipHint>
                            <TooltipHint title={t('userList.table.actionHints.viewGeneralInfo')}>
                                <Link to={`/users/edit/${params.row.user_id}`} style={{marginBottom: '-5px'}}>
                                    <EditIcon/>
                                </Link>
                            </TooltipHint>
                        </div>
                        :
                        <div className={classes.tableActions} style={{
                            justifyContent: 'flex-end'
                        }}>
                            <TooltipHint title={t('userList.table.actionHints.viewGeneralInfo')}>
                                <Link to={`/users/edit/${params.row.user_id}`} style={{marginBottom: '-5px'}}>
                                    <EditIcon/>
                                </Link>
                            </TooltipHint>
                        </div>
                )
            }]
            :
            [
                {
                    field: 'Actions',
                    headerName: '',
                    sortable: false,
                    filterable: false,
                    flex: 0.1,
                    minWidth: 150,
                    renderCell: (params: GridRenderCellParams<UserType, Date>) => {

                        return(
                            <>
                                {
                                    params.row.is_btn_cancel
                                    &&
                                    <button className={classes.cancelBtn} onClick={() => onCancelUserRegistration(params.row.user_id)}>Cancel</button>
                                }
                                {
                                    params.row.is_btn_confirm
                                    &&
                                    <button className={classes.confirmBtn} onClick={() => onUpdateUserRegistration(params.row.user_id)}>Confirm</button>
                                }
                            </>
                        )

                    }


                }
            ]
        ),

    ]

    const handleSortModelChange = (sortModel: GridSortModel) => {
        dispatch(setUserListManualParams({
            ...userListManualParams,
            page: 1,
            sort: sortModel
        }))
    }

    const handleSearch = () => {
        return userList?.filter(u => {
            return u.first_name?.toLowerCase()?.includes(searchValue?.toLowerCase())
                || u?.last_name?.toLowerCase()?.includes(searchValue?.toLowerCase())
                || u?.comment?.toLowerCase()?.includes(searchValue?.toLowerCase())
        })
    }

    const getDataForDisplay = () => {
        let initialList = searchValue?.length ? handleSearch() : userList
        let dataForPagination: UserType[] = userListManualParams.activeStatus === 'ALL' ? initialList || [] : initialList?.filter(user => userListManualParams.activeStatus === 'ACTIVE' ? !!user.is_active : userListManualParams.activeStatus === 'PENDING' ? user.is_confirmed === null  : !user.is_active && user.is_confirmed !== null) || []
        dataForPagination = dataForPagination?.map(user => ({
            ...user,
            rolesString: user.roles.map(r => r.role_name).join(', '),
            creditsBalanceString: String(user?.billing_info?.credits_balance),
            availablePersonString: String(user?.billing_info?.available_person),
        }))
        const updatedData = getDisplayData<UserType>(dataForPagination || [], userListManualParams)
        setDataForDisplay(updatedData)
    }

    useEffect(() => {
        dispatch(setSelectedUserData(null))
        dispatch(setUserDetailsActiveTabKey('general'))
    }, [dispatch])

    useEffect(() => {
        if (!!isAdmin) {
            dispatch(GetUserListThunk(userListRequestParams))
        }
    }, [dispatch, userListRequestParams, isAdmin])

    useEffect(() => {
        getDataForDisplay()
        // eslint-disable-next-line
    }, [userListManualParams, userList, searchValue])

    if (!isAdmin) {
        <Navigate to='/'/>
    }

    return (
        <>
            <div className={classes.titleWrapper}>
                <h1 className='pageTitle'>
                    {t('userList.title')}
                </h1>
                <Link to='/users/add'>
                    <Button type='primary'>
                        + {t('userList.toolbar.addUserBtn')}
                    </Button>
                </Link>
            </div>
            <h2 className='pageDescription'>
                {t('userList.description')}
            </h2>

            {userList === null ? (
                <Spin style={{width: '100%'}}/>
            ) : (
                <>
                    <RadioGroup
                        options={userStatusOptions}
                        onChange={(value) => {
                            dispatch(setUserListManualParams({
                                ...userListManualParams,
                                page: 1,
                                activeStatus: value as UserStatusType,
                            }))
                        }}
                        value={userListManualParams.activeStatus}
                    />

                    <Input
                        placeholder={t('userList.toolbar.searchPlaceholder')}
                        prefix={<SearchIcon/>}
                        style={{width: '400px', marginBottom: '24px'}}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />

                    <MuiTable
                        columns={columns}
                        rows={dataForDisplay?.data || []}
                        apiRef={apiRef}
                        sortingMode='server'
                        getRowId={(row) => row?.user_id}
                        count={dataForDisplay.totalCount}
                        page={userListManualParams.page - 1}
                        onPageChange={(_, page) => {
                            dispatch(setUserListManualParams({
                                ...userListManualParams,
                                page: page + 1,
                            }))
                        }}
                        onSortModelChange={handleSortModelChange}
                        sortModel={userListManualParams?.sort?.length ? [{
                            field: userListManualParams?.sort?.[0]?.field,
                            sort: userListManualParams?.sort?.[0]?.sort?.toLowerCase()
                        }] : []}
                        filterModel={userListManualParams.filters}
                        onFilterModelChange={(model) => {
                            dispatch(setUserListManualParams({
                                ...userListManualParams,
                                page: 1,
                                filters: model
                            }))
                        }}
                    />
                </>
            )}
            <ConfirmationModal
                open={!!confirmationModalData?.user?.user_id}
                title={`${t(`userList.table.actionConfirmation.${confirmationModalData?.type!}`, {name: confirmationModalData?.user?.first_name + ' ' + confirmationModalData?.user?.last_name})}`}
                description={t(`warnings.${confirmationModalData?.type!}`, {
                    ns: 'app',
                    itemName: t('user', {ns: 'app'})
                })}
                onClose={() => setConfirmationModalData(null)}
                onConfirm={() => {
                    if (confirmationModalData?.type === 'delete') {
                        return dispatch(DeleteUserThunk(confirmationModalData?.user?.user_id!))
                            .then(() => setConfirmationModalData(null))
                    } else {
                        return dispatch(EditUserThunk({
                            userId: confirmationModalData?.user?.user_id!,
                            userData: {
                                ...getUserGeneralData(confirmationModalData?.user!),
                                is_active: !confirmationModalData?.user?.is_active
                            }
                        })).then(() => setConfirmationModalData(null))
                    }
                }}
                type={confirmationModalData?.type === 'activate' ? 'success' : 'delete'}
                confirmText={t(`actions.${confirmationModalData?.type!}`, {ns: 'app'})}
            />
        </>
    )
}

export default UserList
